import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { IP } from "./config.js";



import Tags from "@yaireo/tagify/dist/react.tagify"
import "@yaireo/tagify/dist/tagify.css"

export default function Matcher() {
  const [disabled, setDisabled] = React.useState(false);
  const [emails, setEmails] = React.useState([
      // {"idx": 0, "email": "EMAIL", "date": (new Date()).toISOString(), "vote": "none"}
  ]);
  const [currEmail, setCurrEmail] = React.useState("");
  const [currFeedback, setCurrFeedback] = React.useState("");
  const [contractorId, setContractorId] = React.useState("af887453-2687-4190-9500-db9fd7c90548");
  const [token, setToken] = React.useState("eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IlJtS0RyeHBKd196MmFmZmF6YnFaTyJ9.eyJmcmF0Y2hfdXNlciI6eyJpZCI6IjEyNzI2ZmU1LWZmMmEtNDEzZC04Y2E5LThjNDllMDA5YWE1MCIsIm5hbWUiOiJkYW5pZWxAZnJhdGNoLmlvIiwicGVybWlzc2lvbnMiOiJjb250cmFjdG9yOm1hbmFnZSxmaWxlOm1hbmFnZSxtZXNzYWdpbmc6bWFuYWdlLHByb2plY3Q6bWFuYWdlLHVzZXI6Z2V0In0sImF1dGhfdXNlciI6eyJlbWFpbCI6ImRhbmllbEBmcmF0Y2guaW8iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZX0sImlzcyI6Imh0dHBzOi8vZnJhdGNoLXByb2QuZXUuYXV0aDAuY29tLyIsInN1YiI6ImF1dGgwfDYzZDJkNGYyMWFjNDIzNjFmZWIxNDg2ZCIsImF1ZCI6WyJmcmF0Y2guaW8iLCJodHRwczovL2ZyYXRjaC1wcm9kLmV1LmF1dGgwLmNvbS91c2VyaW5mbyJdLCJpYXQiOjE2NzU2ODI3MzAsImV4cCI6MTY3NTc2OTEzMCwiYXpwIjoieGFZTGRWcktLUHpTbTE2NDZvUzlZNFNIcndlVmJaR2IiLCJzY29wZSI6Im9wZW5pZCBwcm9maWxlIGVtYWlsIiwicGVybWlzc2lvbnMiOltdfQ.NdiYmqF-4MlHBkf3sq7sBZV0K2fqeX-mPRK_6iYBS9OF0udyXQShXpbnp8YQ6ayyJ8gr9pvSZXnI0NWYESotMwJbLwJL-F6OPeJlDVOYmKA9SX-_dnmXs-DznXjS8qcngVIp5c6EsRFvvELBq-qyGQKZMtwNaNXoI6waGsu5AUWMJ-ppbSOl4DTFAqkc0PtnSiyhcGvVXWXknt0xOw8RniyJr9bdEpbsv6y2ydaawAw7Kqq2Rjnv9_v4konEKLiOQvPIEQ7Bva52NIpBv-04JNjI0zJNYUS60aK1T_mRUn8VuVIwaVhU2qQtQsZqRaP4O5E5jm0JKGnVKypZMt6UwQ");
  // const [projectId, setProjectId] = React.useState("");

  function addEmail(email) {
    var datestr = (new Date()).toISOString();
    var newEmails = [
      ...emails,
      {"idx": emails.length, "email": email, "date": datestr, "vote": "none"}
    ];
    console.log(newEmails);
    setEmails(newEmails);
    return newEmails;
  }

  function _addEmail(lastEmails, email) {
    var datestr = (new Date()).toISOString();
    var newEmails = [
      ...lastEmails,
      {"email": email, "date": datestr}
    ];
    console.log(newEmails);
    setEmails(newEmails);
    return newEmails;
  }

  function addAndPredict() {
    setDisabled(true);

    var newEmails = addEmail(currEmail);
    setCurrEmail("");

    loadMessaging(newEmails, contractorId);
  }

  function handleChange(evt) {
    const value = evt.target.value;
    setCurrEmail(value);
  }

  function handleFeedbackChange(evt) {
    const value = evt.target.value;
    setCurrFeedback(value);
  }

  function loadMessaging(newEmails, id_) {
    var form = new FormData();
    form.append("context", JSON.stringify(newEmails.slice(-3)));

    console.log(newEmails);

    fetch(IP + 'messaging/' + id_, {
      method: 'POST',
      mode: 'cors',
      body: form,
      credentials: "include",
      headers: {
        "Authorization": token
      },
    }).then((resp) => {
      resp.text().then((finaldata) => {
        console.log(finaldata);
        _addEmail(newEmails, finaldata);
        setDisabled(false);
      });
    });
  }

  function vote(idx, vote, save) {
    var newEmails = [
      ...emails
    ];
    newEmails[idx]["vote"] = vote;
    setEmails(newEmails);

    console.log(newEmails);

    if (save) {
      saveFeedback(newEmails);
    }
    // in the other case ("no"), we save async from a button click.
  }

  function saveFeedback(newEmails) {
    var form = new FormData();
    form.append("context", JSON.stringify(newEmails.slice(-3)));
    form.append("vote", vote);
    form.append("feedback", currFeedback);

    fetch(IP + 'messaging/vote/' + contractorId, {
      method: 'POST',
      mode: 'cors',
      body: form,
      credentials: "include",
      headers: {
        "Authorization": token
      },
    }).then((resp) => {
      resp.text().then((finaldata) => {
        console.log(finaldata);
        alert("Saved!");
      });
    });
  }

  useEffect(() => {
    // loadProjects();
  }, []);

  return (
    <>
      <div className="mt-10 sm:mt-0 flex flex-column justify-center">
        <div className="mx-5 px-5 border-r-2" style={{width: "200px"}}>
          <div className="px-1 py-3 border-2 border-gray-300 cursor-pointer">
            Current conversation
          </div>

          <button
            onClick={() => {}}
            type="button"
            className="mt-3 items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-black bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 text-center">

            New Conversation
          </button>
        </div>
        <div style={{width: "600px", maxWidth: "90%"}}>
        <div className="md:grid md:grid-cols-1 md:gap-6 text-left mb-3">
          Automation of Tri-laterial messaging between a mediator, a contractor, and a project owner. Type in desired contractor and project to analyze.
        </div>
        <input onChange={(evt) => setContractorId(evt.target.value)} type="text" className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md my-2" placeholder="Contractor Id" />
        {/*<input onChange={(evt) => setProjectId(evt.target.value)} type="text" className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md my-2" placeholder="Project Id" />*/}
        <input onChange={(evt) => setToken(evt.target.value)} type="text" className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md my-2" placeholder="Bearer Token" />

        <div className="md:grid md:grid-cols-1 md:gap-6 text-left mb-3">
          Email Thread. Try it out.
        </div>

        <div className="md:grid md:grid-cols-1 md:gap-6">
          <div>
            <div>
            {emails.map((email) => (
              <div className="text-right" key={email["date"]}>
              <div className="text-left border-b-4 mt-5">
                <pre style={{whiteSpace: "pre-wrap"}}>
                  { email["email"] }
                </pre>
              </div>
              <span className="text-xs text-slate-400 flex items-center justify-end py-2">
<svg onClick={() => vote(email["idx"], "yes", true)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill={email["vote"] === "yes" ? "currentColor" : "none"} stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-thumbs-up cursor-pointer mr-3 inline"><path d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3"></path></svg>
<svg onClick={() => vote(email["idx"], "no", false)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill={email["vote"] === "no" ? "currentColor" : "none"} stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-thumbs-down cursor-pointer mr-3 inline"><path d="M10 15v4a3 3 0 0 0 3 3l4-9V2H5.72a2 2 0 0 0-2 1.7l-1.38 9a2 2 0 0 0 2 2.3zm7-13h2.67A2.31 2.31 0 0 1 22 4v7a2.31 2.31 0 0 1-2.33 2H17"></path></svg>
                { email["date"] }
              </span>
{ email["vote"] === "no" ?
    <>
                <textarea rows={9} name="header_ret" id="header_ret" placeholder="Write a better version of the E-Mail here."
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  onChange={handleFeedbackChange}
                />
                <button
                  onClick={() => vote(email["idx"], "no", true)}
                  type="button"
                  className="mt-3 items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-black bg-yellow-200 hover:bg-yellow-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-400 text-center">
                    Save Feedback
                </button>
    </>
: ""}
              </div>
            ))}
            </div>

            <div className="flex items-center justify-center mt-5">
              <div className="flex border-2 rounded w-full">

                <textarea rows={9} name="header_ret" id="header_ret" placeholder="E-Mail content"
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  value={currEmail}
                  onChange={handleChange}
                />

              </div>
            </div>

            <button
              disabled={disabled}
              onClick={() => addAndPredict()}
              type="button"
              className="mt-3 items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-black bg-yellow-200 hover:bg-yellow-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-400 text-center">

{ disabled ? 
              <div className="flex justify-center items-center">
                <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
: 'Predict & Next' }
            </button>

          </div>

        </div>
        </div>
      </div>
    </>
  )
}
