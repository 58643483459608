import React, { Component }  from 'react';
import postLogo1 from './fratch_logo.png';
import { Link } from "react-router-dom";

export default function Header() {
  return (
      <div className="drop-shadow-md bg-indigo-100 h-20 flex items-center justify-left text-sm font-medium text-black px-10 sm:px-6 lg:px-10 border-b-4 border-indigo-500">

        <Link className="grow-0 shrink-0 basis-24 underline" style={{flexGrow: 0, flexShrink: 0}} to="/">
        <img src={postLogo1} alt="Logo" className="postlogo mr-2" />
        </Link>
        <p className="text-left">
          <Link className="underline" to="/">Messaging</Link>
        </p>
        <p className="w-full text-right text-gray-500">
          v0.2.1
        </p>
      </div>
  )
}
