import React, { Component }  from 'react';
import './App.css';
import Messaging from './H_Messaging.js';
import Header from './H_Header.js';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <div className="bg-white overflow-hidden rounded-lg">
          <div className="px-100 py-10 sm:p-10">
            <Routes>
              <Route path="/" element={<Messaging/>}></Route>
            </Routes>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
